import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "edit-a-mobile-app"
    }}>{`Edit a Mobile App`}</h1>
    <hr></hr>
    <p>{`To edit a Mobile App within the Content Management System, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Select the app you want wish to edit. `}</li>
      <li parentName="ol">{`To change the name of the app as it appears in the Content Management System, enter in the name of the app in the 'Name' field.`}</li>
      <li parentName="ol">{`To change the app name as it appears within your app, enter in the name in the 'Published Title' field. `}</li>
      <li parentName="ol">{`To make any changes to the select Data, Search Data, Events Data, Tracker Data or List Data Provideres, you will need to reach out to your support representative for assistance. `}</li>
      <li parentName="ol">{`Provide an icon image that will be used as app's icon image on the patron's device. Click 'View' to review the current image. To change or update the image, click 'Replace'. `}<em parentName="li">{`Note the best size for the icon image is 400 px by 400 px.`}</em></li>
      <li parentName="ol">{`Provide an image to be used for the splash screen. This is the first screen image patrons sees when they open up the app and it is loading. Click 'View' to review the current image. To change or update the image, click 'Replace'. `}</li>
      <li parentName="ol">{`Provide an image to be used as the main header within the app. Click 'View' to review the current image. To change or update the image, click 'Replace'.`}</li>
      <li parentName="ol">{`Set the Header Image alignment to your desired preference of either, Left, Center, Right or Large. `}</li>
      <li parentName="ol">{`Set your desired measurement for distances for your app. This will detail if you want distances shown in metric of imperial units. `}</li>
      <li parentName="ol">{`Enable Selectable if you wish to allow your patron's to toggle between your templates. When a patron selects a new template within the app, that template will load. `}</li>
      <li parentName="ol">{`Select which time zone to be used within the app in the Time Zone dropdown menu. `}</li>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      